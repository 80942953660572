import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Response, Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';
import type { StrategyOptions, StrategyHandler, Strategy } from 'o365.pwa.declaration.sw.workbox.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

declare module 'o365.pwa.declaration.sw.strategies.api.file.strategy.d.ts' {
    export type ApiFileRoute = 'VIEW' | 'DOWNLOAD' | 'UPLOAD' | 'OLD-CHUNK-UPLOAD' | 'CHUNK-UPLOAD' | 'CHUNK-INITIATE' | 'CHUNK-SETCRC' | 'UPLOAD-PROGRESS' | 'PDF-VIEW' | 'PDF-DOWNLOAD';

    export interface IApiPwaStrategyOptions extends StrategyOptions {
        apiFileRoute: ApiFileRoute;
    }

    export class ApiFileStrategy extends Strategy {
        constructor(options: IApiPwaStrategyOptions);

        _handle(request: Request, handler: InstanceType<typeof StrategyHandler>): Promise<Response | undefined>;

        static getAppRecords(options: any): Promise<any>;
        static handleResponse(data: Blob, mimeType: string, disposition: string, filename?: string): Response;
        static createNewFileRecord(primKey: string, uploadBody: any, originalRef?: string): any;
        static createNewAppRecord(filePrimKey: string, uploadBody: any, appPrimKey: string): any;
    }
}
